import {useEffect, useState} from "react";
import ChannelService from "../../services/channel/channel.service";
import {AxiosError} from "axios";
import {AsideCardLayout} from "../AsideCardLayout/AsideCardLayout";
import {Box, CircularProgress, Typography} from "@mui/material";
import {TableDefault} from "../Table/TableDefault";
import {AccordionWrapper} from "../AccordionWrapper/AccordionWrapper";
import companyUsersStore from "../../store/companyUsers.store";
import {getTransferredChannelsAccordionItems, getTransferredChannelsItems} from "./shared/dataList";
import transferredChannelsStore from "../../store/transferredChannels.store";
import {observer} from "mobx-react-lite";
import userStore from "../../store/user.store";
import {ALL_RULES} from "../../shared/rules/interface/EnumRights";
import {NotificationAlerts} from "../NotificationAlerts/NotificationAlerts";
import {IChannel} from "../../services/channel/ICnannelResponse";
import {ChannelTransferredList} from "./ui/ChannelTransferredList";
import {Route, Routes} from "react-router-dom";
import {channelMainSectionRoutes} from "../../shared/routes/routes";



export const TransferredChannels = observer(() => {

  const [tableError, setTableError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isShowAlert, setShowAlert] = useState(false)

  const getTransferredChannelData = async () => {
    setIsLoading(true)
    setTableError(null)
    const userId = userStore.user?.id
    try {
      const res = await ChannelService.getTransferredChannels(userId)
      transferredChannelsStore.setTableItems(res.data.allChannelsStatusTransferred)
      transferredChannelsStore.setTableAccordionItems(res.data.channelsAssignedToManagers)
    } catch (err) {
      if (err instanceof AxiosError) {
        setTableError(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeTableHandler = (item: IChannel) => {
    transferredChannelsStore.deleteTransferredChannelsItem(item.id)
    transferredChannelsStore.addTransferredChannelsItem(item)
    setShowAlert(true)
  }

  const onChangeStatusTableHandler = (id: number, update_id: number, value: string) => {
    transferredChannelsStore.changeUpdateIdItem(id, update_id, value)
    transferredChannelsStore.deleteTransferredChannelsItemByStatus(value, id)
  }

  const onChangeStatusAccordionTableHandler = (id: number, update_id: number, value: string) => {
    transferredChannelsStore.changeAccordionChannelUpdateIdItem(id, update_id, value)
    transferredChannelsStore.deleteTransferredAccordionChannelsItem(value,id)
  }

  const onChangeManagerHandler = (id: number, field: string, update_id: number, value: number) => {
    transferredChannelsStore.addReleaseByManager(id, field, update_id, +value)
  }


  useEffect(() => {
    let timerId = setTimeout(() => {
      getTransferredChannelData()
    }, 500)
    return () => {
      clearTimeout(timerId)
      transferredChannelsStore.setTableItems([])
      transferredChannelsStore.setTableAccordionItems([])
    }
  }, [])


  const userHasEditRights = (
    userStore.user?.rights?.some(x => x.code === ALL_RULES.release) &&
    !userStore.user?.rights?.some(x => x.code === ALL_RULES.search_head)
  )

  const allChannelsBlock = (
    <Box mb={2} borderBottom={'1px solid #e0e0e0'} borderRadius={'4px'} overflow={'hidden'}>
      <Typography variant={'h5'} mb={2}>Переданные каналы</Typography>
      <AccordionWrapper
        isOpen
        title='Все каналы'>
        <TableDefault showBorderRadius={false} tableRow={getTransferredChannelsItems()} showBurgerCell>
          <ChannelTransferredList
            readOnlyDateGiven={!(userStore.user?.rights?.find(x => x.code?.includes(ALL_RULES.edit_channel_transfer) || x.code?.includes(ALL_RULES.ADMIN)))}
            channels={transferredChannelsStore.tableItems}
            cells={getTransferredChannelsItems()}
            readOnly={!(userStore.user?.rights?.find(x => x.code?.includes(ALL_RULES.edit_channel_transfer) || x.code?.includes(ALL_RULES.ADMIN)))}
            onChange={onChangeTableHandler}
            onChangeStatus={onChangeStatusTableHandler}
          />
        </TableDefault>
      </AccordionWrapper>
    </Box>
  );

  return (
    <>
      {isLoading &&
			 <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
				Идёт загрузка таблицы... <CircularProgress sx={{ml: 2}}/>
			 </Box>
      }
      {!isLoading && tableError &&
			 <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
				<Typography variant='h5' color='error'>Ошибка с таблицей! {tableError}</Typography>
			 </Box>
      }
      {!isLoading && !tableError &&
			 <AsideCardLayout sx={{p: {xs: 1, sm: 2,}, overflow: 'auto',height: '100%'}}>
         {!userHasEditRights && allChannelsBlock}
				<Box mb={2} borderBottom={'1px solid #e0e0e0'} borderRadius={'4px'} overflow={'hidden'}>
				 <Typography variant={'h5'} mb={2}>Закрепленные за менеджерами каналы</Typography>
          {transferredChannelsStore.tableAccordionItems && transferredChannelsStore.tableAccordionItems.map(({ managerId, channels }) => {
            const manager = companyUsersStore.users.find(item => item.id === +managerId);
            const isOpen = userStore.user?.id === manager?.id
            return (
              <AccordionWrapper
                title={`Выпускающий менеджер: ${manager?.firstName} ${manager?.lastName} (${channels.length ? channels.length : 0})`}
                key={managerId}
                isOpen={isOpen}
              >
                <TableDefault showBorderRadius={false} tableRow={getTransferredChannelsAccordionItems()} showBurgerCell>
                  <ChannelTransferredList
                    channels={channels}
                    cells={getTransferredChannelsAccordionItems()}
                    readOnly={!(userStore.user?.rights?.find(x => x.code?.includes(ALL_RULES.edit_channel_transfer) || x.code?.includes(ALL_RULES.ADMIN)))}
                    onChangeStatus={onChangeStatusAccordionTableHandler}
                    onChangeManager={onChangeManagerHandler}
                  />
                </TableDefault>
              </AccordionWrapper>
            );
          })}
				</Box>
         {userHasEditRights && allChannelsBlock}
				<Routes>
          {channelMainSectionRoutes.map(({path, element: Element}) => <Route key={path} path={path} element={<Element/>}/>)}
				</Routes>
				<NotificationAlerts
				 sucsess={{text: 'Выпускающий менеджер установлен', open: isShowAlert, onClose: () => setShowAlert(false)}}/>
			 </AsideCardLayout>}
    </>

  )
})


