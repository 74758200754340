import {FC, memo} from 'react'
import { IFinancesPaymentItemComponent } from './IFinancesPaymentItem'
import { Box, Divider, Stack, TextField, Typography } from '@mui/material'
import { FinancesDateInput } from '../FinancesDateInput/FinancesDateInput'
import {priceTransormFormat} from '../../../../../../shared/functions/functions'
import { PaymentTypeSelect } from '../../../FinancesOplata/ui/PaymentTypeSelect/PaymentTypeSelect'
import { FinancePaymentInput } from './ui/FinancePaymentInput/FinancePaymentInput'
import { FinancePaymentItemStatus } from './ui/FinancePaymentItemStatus/FinancePaymentItemStatus'
import dayjs from 'dayjs'
import { EmptyText } from '../../../../../../components/EmptyText/EmptyText'
import { PaymentDeleteButton } from './ui/PaymentDeleteButton/PaymentDeleteButton'
import {TemplateProcessorPays} from "./ui/TemplateProcessorPays/TemplateProcessorPays";


const PAY_CHECK_FROM_SELF_EMPLOYED = 'pay_check_from_self_employed'


export const FinancesPaymentItem:FC<IFinancesPaymentItemComponent> = memo((props) => {
  const {
    indexPay,
    errorMessages,
    direction,
    paymentType,
    source,
    totalSumm,
    cardOrPhone,
    fio,
    paymentLink,
    plannedPaidAt,
    id,
    showDelete = true,
    showDivider = true,
    showEditPrice = false,
    onChangeField,
    counterAgentName,
    title,
    changePaymentType,
    onDeletePayment,
    paymentStatusCode,
    isShowPaymentStatus,
    isEditMode,
    onChangeStatus,
    paidAt,
    readOnlyStatus,
    update_id,
    isTotalSummError,
    pattern
  } = props

  console.log(paymentType)

  const getErrorForField=(field:string):string=>{
    return errorMessages?.filter(el=>el.includes(field)).map(str => str.split(`pays.${indexPay}.${field};`).join(''))[0] ||''
  }

  const template = pattern?.find(el=>el.code=== PAY_CHECK_FROM_SELF_EMPLOYED)

  return (
    <Box sx={{position:'relative',}}>
      <Stack 
        direction={'row'} 
        alignItems={'center'} 
        justifyContent={'space-between'} 
        sx={{mt:'6px'}}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={'6px'}>
          <Typography noWrap fontWeight={'bold'}>{title}</Typography>
          {template && <TemplateProcessorPays disabled={paymentStatusCode!=='paid'} template={template} text={'Запрос чека'} titleModal={'Запрос ссылки на текст'}/>}
          {isShowPaymentStatus && paymentStatusCode && onChangeStatus ? 
            <FinancePaymentItemStatus 
              paymentStatusCode={paymentStatusCode} 
              id={id} 
              onChangeStatus={onChangeStatus}
              isEditMode={isEditMode}
              paymentType={paymentType}
              plannedPaidAt={plannedPaidAt}
              totalSumm={totalSumm || 0}
              readOnlyStatus={readOnlyStatus}
              update_id={update_id}
            /> 
            : null
          }
        </Stack>
        {showDelete &&
          <PaymentDeleteButton id={id} onDelete={onDeletePayment}/>
        }
      </Stack>
      <Box
        sx={{
          display:{xs:'block', md:'grid'}, 
          gridTemplateColumns:'calc(50% - 6px) 50%', 
          columnGap:'6px',
          rowGap:'6px',
          mt:'6px',
          alignItems: 'center',
        }}
      >
        <Typography noWrap>Тип оплаты{isEditMode ? <Typography component='span' color={'error'}>*</Typography> : null}</Typography>
        <PaymentTypeSelect 
          id={id}
          field_code='paymentType'
          field='type' 
          value={paymentType}
          onChange={()=>''}
          onChangeField={changePaymentType}
          placeholder='Выберите тип оплаты'
          isEditMode={isEditMode}
        />
        <Typography>Дата плановой оплаты платежа{isEditMode ? <Typography component='span' color={'error'}>*</Typography> : null}</Typography>
        <FinancesDateInput
          error={!!getErrorForField('plannedPaidAt') && !plannedPaidAt}
          value={plannedPaidAt} 
          onChange={onChangeField}
          field='plannedPaidAt'
          id={id}
          isEditMode={isEditMode}
        />
        {!isEditMode &&
          <>
            <Typography>Дата факт. оплаты:</Typography>
            <Typography>
              {paidAt ? `${dayjs(paidAt)?.format('DD.MM.YYYY')}` : <EmptyText/>}
            </Typography>
          </>
        }
        <Typography>Сумма платежа{(showEditPrice && isEditMode) ? <Typography component={'span'} color={'error'}>*</Typography> : null}</Typography>
        {(totalSumm !== null && showEditPrice === false) &&
          <Typography>{priceTransormFormat(totalSumm)}</Typography>
        }
        {(totalSumm === null && showEditPrice === false) &&
          <Typography color={'grey'} fontWeight={'100'}>Добавьте интеграцию</Typography>
        }
        {showEditPrice === true &&
          <FinancePaymentInput
            error={(!!getErrorForField('totalSumm') && !totalSumm)||isTotalSummError}
            id={id}
            field='totalSumm'
            value={totalSumm?.toString() || ''}
            changeField={onChangeField}
            placeholder='Введите сумму'
            inputType='number'
            isEditMode={isEditMode}
            isPrice
          />
        }
        {paymentType === 'bank' &&
          <>
            <Typography>Ссылка на счёт</Typography>
            <FinancePaymentInput
              id={id}
              field='paymentLink'
              value={paymentLink ? paymentLink : (isEditMode ? '' : (<EmptyText/>) as any)}
              changeField={onChangeField}
              isEditMode={isEditMode}
              isLink={paymentLink ? true : false}
            />
          </>
        }
        {paymentType === 'qr-code' &&
          <>
            <Typography>Ссылка на QR-код</Typography>
            <FinancePaymentInput
              id={id}
              field='paymentLink'
              value={paymentLink ? paymentLink : (isEditMode ? '' : (<EmptyText/>) as any)}
              changeField={onChangeField}
              isEditMode={isEditMode}
              isLink={paymentLink ? true : false}
            />
          </>
        }
        {paymentType === 'card' &&
          <PaymentTypeSelect
					  error={!!getErrorForField('source') && !source}
            id={id}
            field={'bank'}
            value={source}
            onChange={()=>''}
            onChangeField={onChangeField}
            field_code='source'
            titleContent={<Typography color={'error'}>ОТКУДА</Typography>}
            placeholder={'Выберите банк'}
            isCompanyBank
            isEditMode={isEditMode}
          />
        }
        {(paymentType === 'bank' || paymentType === 'qr-code'|| paymentType==='netting' ) &&
          <PaymentTypeSelect
					  error={!!getErrorForField('source')&& !source}
            id={id}
            field={'account'} 
            value={source}
            onChange={()=>''}
            onChangeField={onChangeField}
            field_code='source'
            titleContent={<Typography color={'error'}>ОТКУДА</Typography>}
            placeholder={'Выберите ИП/ООО'}
            isCompanyBank
            isEditMode={isEditMode}
          />
        }
        {paymentType === 'card' &&
          <PaymentTypeSelect
					  error={!!getErrorForField('direction') && !direction}
            id={id}
            field={'bank'} 
            value={direction} 
            onChange={()=>''}
            onChangeField={onChangeField}
            field_code='direction'
            titleContent={<Typography color={'green'} sx={{mt:{xs:1, md:0}}}>КУДА</Typography>}
            placeholder={'Выберите банк'}
            isEditMode={isEditMode}
          />
        }
        {(paymentType === 'bank' || paymentType === 'qr-code'|| paymentType==='netting') &&
          <Stack spacing={0.5}>
            <Typography color={'green'} sx={{mt:{xs:'6px', md:0}}}>КУДА</Typography>
            {isEditMode
              ? <TextField value={counterAgentName || ''} size='small' InputProps={{readOnly:true,error:!!getErrorForField('direction')&& !direction}} placeholder='Выберите контрагента'/>
              : <Typography noWrap>{counterAgentName || <EmptyText>контрагент не выбран</EmptyText>}</Typography>
            }
          </Stack>
        }
        {paymentType === 'card' &&
          <>
            <FinancePaymentInput
              id={id}
              field='cardOrPhone'
              title='Карта или номер телефона'
              value={cardOrPhone ? cardOrPhone : (isEditMode ? '' : (<EmptyText/>) as any)}
              changeField={onChangeField}
              inputType='number'
              isEditMode={isEditMode}
            />
            <FinancePaymentInput
              id={id}
              field='fio'
              title='ФИО'
              value={fio ? fio : (isEditMode ? '' : (<EmptyText/>) as any)}
              changeField={onChangeField}
              isEditMode={isEditMode}
            />
          </>
        }
      </Box>
      {showDivider &&
        <Divider sx={{mt:'6px'}}/>
      }
    </Box>
  )
})