import { FC, useState, useEffect } from 'react'
import { IOrdSelectAdvertisement } from './IOrdSelectAdvertisement'
import { CustomSelect } from '../../../../../../../../components/UI/CustomSelect/CustomSelect'
import { AxiosError } from 'axios'
import { Box, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import PaymentsService from '../../../../../../../../services/payments/payments.service'
import { IordTextInsertRequest } from '../../../../../../../../services/payments/IPaymentsRequest'
import { observer } from 'mobx-react-lite'
import ordTextStore from '../../../../../../../../store/ordText.store'
import { CustomIconButton } from '../../../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton'
import {AlertModal} from "../../../../../../../../components/AlertModal/AlertModal";

export const OrdSelectAdvertisement:FC<IOrdSelectAdvertisement> = observer((props) => {
  const {
    onChange,
    text,
    field,
    readOnly = false,
    id,
    ordInfo,
    title
  } = props

  const [showModalAddedText, setShowModalAddedText] = useState(false)
  const [loadingAddedText, setLoadingAddedText] = useState(false)

  const addedOrdText = (text:string) => {
    setLoadingAddedText(true)
    const option: IordTextInsertRequest = {
      text,
      goodsCategory:ordInfo?.integration?.goodsCategoryCode || ''
    }
    PaymentsService.ordTextInsert(option)
      .then(res=>{
        ordTextStore.addOrdText({name:res.data?.text, value:`${res.data?.id}`, filterCode:`${res.data?.goodsCategory?.code}`})
        onChange(res.data as any, field, 'attachment', id)
        setShowModalAddedText(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })
      .finally(()=>{
        setLoadingAddedText(false)
      })
  }

  const changeSelect = (event:SelectChangeEvent<string>) => {
    onChange({text:ordTextStore.ordTexts?.find(x=>x?.value === event.target.value)?.name || '', id:event.target.value} as any, field, 'attachment', id)
  }

  useEffect(()=>{
    if(ordTextStore.ordTexts && !text){
      const currentText = ordTextStore.ordTexts.find(x=>x?.filterCode === ordInfo?.integration?.goodsCategoryCode)
      onChange(text ? ordInfo.text : {text:currentText?.name || '', id:currentText?.value || ''} as any, field, 'attachment', id)
    }
  },[ordTextStore.ordTexts])

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Stack direction={'row'} alignItems={'center'} sx={{width:'50%'}}>
          <Typography noWrap>{title}</Typography>        
          <CustomIconButton size='small' onClick={()=>setShowModalAddedText(true)} showBackground sx={{ml:0.5}}>
            <Add fontSize='small'/>
          </CustomIconButton>   
        </Stack>    
        <Box sx={{width:'50%'}}>
          {ordTextStore.ordTexts 
            ? <CustomSelect
                content={ordTextStore.ordTexts}
                value={text ? text : ordTextStore.ordTexts.find(x=>x?.filterCode === ordInfo?.integration?.goodsCategoryCode)?.value || ''}
                onChange={changeSelect}
                disabled={readOnly}
                placeholder='Выберите значение'
                fullWidth
                noWrap
              />
            : <Skeleton variant='rounded' sx={{minHeight:37, maxHeight:37}}/>
          }
        </Box>
      </Stack>
      {showModalAddedText &&
        <AlertModal
          showTextField
          title='Добавление нового типа рекламного текста'
          open={showModalAddedText}
          onClose={()=>setShowModalAddedText(false)}
          onSubmit={addedOrdText}
          loading={loadingAddedText}
          placeholder='Введите текст'
          subInputText='Рекламный текст:'
				  buttonCancelName='Отмена'
				  buttonSubmitName='Добавить'
        />
      }
    </>
  )
})