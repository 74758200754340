import {FC, memo} from 'react'
import { IOrdCard } from './IOrdCard'
import { Box, Checkbox, Divider, Link, Stack, Tooltip, Typography } from '@mui/material'
import { OrdSelect } from './ui/OrdSelect/OrdSelect'
import { ContractInput } from '../../../ContractBlock/ui/ContractInput/ContractInput'
import { OrdSelectAdvertisement } from './ui/OrdSelectAdvertisement/OrdSelectAdvertisement'
import { OrdSelectEridText } from './ui/OrdSelectEridText/OrdSelectEridText'
import {EmptyText} from "../../../../../../components/EmptyText/EmptyText";
import { CopyIconButton } from '../../../../../../components/UI/Buttons/CopyIconButton/CopyIconButton'

export const OrdCard:FC<IOrdCard> = memo((props) => {
  const {
    ordInfo,
    isEditMode,
    isLoading,
    changeEditCheckbox,
    changeSimpleEditInput,
    changeSelect,
    showDivider = true
  } = props

  return (
    <Box>
      <Stack spacing={'6px'} sx={{mt:'6px'}}>
        <Typography fontWeight={'bold'} sx={{mb:-1}}>Интеграция {ordInfo?.integration?.id} Промокод {ordInfo?.integration?.promokod?.code}</Typography>
        <Box sx={{display:'flex', mb:-1, justifyContent:'space-between', flexDirection:{xs:'column', sm:'row'}}}>
          <Box sx={{display:"flex",}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <Typography whiteSpace={'nowrap'}>Акт внесён в ОРД</Typography>
            </Box>
            <Box sx={{display:'flex', alignItems:'center'}}>
              {isEditMode
                ? <Checkbox
                    checked={Boolean(ordInfo?.hasAct)}
                    onChange={(e)=>changeEditCheckbox(ordInfo?.id, 'hasAct', e)}
                  />
                : <Checkbox
                    checked={Boolean(ordInfo?.hasAct)}
                    onChange={()=>''}
                  />
            }
            </Box>
          </Box>
          <Box sx={{display:"flex",}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <Typography whiteSpace={'nowrap'}>Мы оформляем ОРД</Typography>
            </Box>
            <Box sx={{display:'flex', alignItems:'center'}}>
              {isEditMode
                ? <Checkbox
                    checked={Boolean(ordInfo?.isSelf)}
                    onChange={(e)=>changeEditCheckbox(ordInfo?.id, 'isSelf', e)}
                  />
                : <Checkbox
                    checked={Boolean(ordInfo?.isSelf)}
                    onChange={()=>''}
                  />
              }
            </Box>
          </Box>
        </Box>
        <Stack spacing={'6px'}>
          {!isEditMode &&
            <Stack direction={'row'} alignItems={'center'}>
              <Typography sx={{width:'50%'}}>Текст erid:</Typography>
              <Typography sx={{width:'50%', lineBreak: 'anywhere'}}>{ordInfo?.eridText?.text || <EmptyText/>}</Typography>
            </Stack>
          }
          {isEditMode &&
            <OrdSelectEridText
              text={ordInfo.eridText ? ordInfo?.eridText?.id?.toString() : ''}
              field='eridText' 
              onChange={changeSimpleEditInput}
              id={ordInfo?.id || undefined}
              title={'Текст erid:'}
              ordInfo={ordInfo}
            />
          }
        </Stack>
        <Box sx={{display:'flex'}}>
          <Box sx={{width:'50%', display:'flex', alignItems:'center'}}>
            <Typography>Токен ОРД erid:</Typography>
          </Box>
          <Box sx={{width:'50%', display:'flex', alignItems:'center'}}>
            {isEditMode &&
              <ContractInput
                field="token"
                value={ordInfo?.token || ''}
                onChange={changeSimpleEditInput}
                placeholder="Введите значение"
                disabled={isLoading}
                fullWidth
                id={ordInfo?.id}
              />
            }
            {!isEditMode &&
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{width:'100%'}}>
                <Typography noWrap>{ordInfo?.token || <EmptyText/>}</Typography>
                {ordInfo?.token &&
                  <Tooltip title={<Typography>Копирование Текста erid + Токен ОРД erid</Typography>} placement='top'>
                    <CopyIconButton copyText={ordInfo?.eridText?.text ? `${ordInfo?.eridText.text} ${ordInfo?.token}` : `${ordInfo?.token}`}/>
                  </Tooltip>
                }
              </Stack>
            }
          </Box>
        </Box>
        <Box sx={{display:'flex'}}>
          <Box sx={{width:'50%', display:'flex', alignItems:'center'}}>
            <Typography>Ссылка на креатив:</Typography>
          </Box>
          <Box sx={{width:'50%', display:'flex', alignItems:'center'}}>
            {isEditMode &&
              <ContractInput
                field="linkToCreative"
                value={ordInfo?.linkToCreative || ''}
                onChange={changeSimpleEditInput}
                placeholder="Введите значение"
                disabled={isLoading}
                fullWidth
                id={ordInfo?.id}
              />
            }
            {!isEditMode &&
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{width:'100%'}}>
                <>
                  {ordInfo?.linkToCreative
                    ? <Link href={ordInfo?.linkToCreative} target='_blank' color={'secondary'} noWrap>
                        {ordInfo?.linkToCreative}
                      </Link>
                    : <Typography>{ordInfo?.linkToCreative || <EmptyText/>}</Typography>
                  }
                </>
                {ordInfo?.linkToCreative &&
                  <CopyIconButton copyText={`${ordInfo?.linkToCreative}`}/>
                }
              </Stack>
            }
          </Box>
        </Box>
        <Box sx={{display:'flex'}}>
          <Box sx={{width:'50%', display:'flex', alignItems:'center'}}>
            <Typography>Формат распространения рекламы:</Typography>
          </Box>
          <Box sx={{width:'50%', display:'flex', alignItems:'center'}}>
            {isEditMode &&
              <OrdSelect
                value={ordInfo?.format?.code || ''}
                onChange={changeSelect}
                id={ordInfo?.id}
              />
            }
            {!isEditMode &&
              <Box sx={{display:'flex', alignItems:'center', width:'100%'}}>
                <Typography>{ordInfo?.format?.name || <EmptyText/>}</Typography>
                {ordInfo?.format?.name && <CopyIconButton sx={{ml:'auto'}} copyText={`${ordInfo?.format?.name}`}/>}
              </Box>
            }
          </Box>
        </Box>
        <Stack spacing={1}>
          {!isEditMode &&
            <Stack direction={'row'} alignItems={'center'}>
              <Typography sx={{width:'50%'}}>Рекламный текст:</Typography>
              <Box sx={{display:'flex', alignItems:'center', width:'50%'}}>
                <Typography>{ordInfo?.text?.text || <EmptyText/>}</Typography>
                <CopyIconButton copyText={`${ordInfo?.text?.text}`}/>
              </Box>
            </Stack>
          }
          {isEditMode &&
            <OrdSelectAdvertisement
              text={ordInfo.text ? ordInfo?.text?.id?.toString() : ''}
              field='text' 
              onChange={changeSimpleEditInput}
              id={ordInfo?.id || undefined}
              ordInfo={ordInfo}
              title={'Рекламный текст:'}
            />
          }
        </Stack>
      </Stack>
      {showDivider && <Divider sx={{mt:1.5}}/>}
    </Box>
  )
})