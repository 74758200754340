import { FC } from 'react'
import { IGoodSampleBlock } from './IGoodSampleBlock'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { EditModeButton } from '../../../../components/UI/Buttons/EditModeButton/EditModeButton'
import { Stack, Divider, TextField, Typography, Box, Skeleton, CircularProgress } from '@mui/material'
import { GoodSampleItemWrapper } from './ui/GoodSampleItemWrapper/GoodSampleItemWrapper'
import { GoodsProductLists } from './ui/GoodsProductLists/GoodsProductLists'
import { DateCustomInput } from '../../../../components/UI/DateCustomInput/DateCustomInput'
import { CustomSelect } from '../../../../components/UI/CustomSelect/CustomSelect'
import { observer } from 'mobx-react-lite'
import { getManagersOnType } from '../../../DashboardPage/pages/MotivationPage/ui/FilterMotivation/shared/dataList'
import AllUsersStore from '../../../../store/AllUsers.store'
import selectsDataStore from '../../../../store/selectsData.store'
import { getCitiesFilterItems } from '../../../GoodsPage/ui/GoodsFilter/shared/dataList'
import { GoodCheckbox } from './ui/GoodCheckbox/GoodCheckbox'
import dayjs from 'dayjs'
import { GoodSearchIntegration } from './ui/GoodSearchIntegration/GoodSearchIntegration'
import { IUser } from '../../../../entities/user/models/IUser'
import { priceTransormFormat } from '../../../../shared/functions/functions'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";
import { CustomButton } from '../../../../components/UI/Buttons/CustomButton/CustomButton'
import { AddedButton } from '../../../../components/UI/Buttons/AddedButton/AddedButton'
import { GoodsInputNum } from './ui/GoodsInputNum/GoodsInputNum'

export const GoodSampleBlock:FC<IGoodSampleBlock> = observer((props) => {
  const {
    isEdit,
    onOpenEdit,
    onCloseEdit,
    headerTitle,
    editTitle,
    showEditButton = false,
    productSampleList,
    onAddedGood,
    onSubmitForm,
    onDeleteGood,
    disabled,
    loading,
    onChangeCheckbox,
    onChangeCity,
    onChangeDate,
    onChangeGood,
    onManagerChange,
    hasRefundValue,
    sendFrom,
    sendAtValue,
    userId,
    showRefundContent,
    onChangeIntegration,
    integration,
    showAddedBtn,
    deliveryCostValue,
    setDeliveryCostValue,
    fioValue,
    phoneValue,
    refundCostValue,
    setRefundCostValue,
    sendToValue,
    trackNumberCGValue,
    trackNumberRefundValue,
    trackNumberTCValue,
    isSampleRefundValue,
    onChangeSampleRefund,
    headerIcon,
    headerContent
  } = props

  const manager:IUser | null = AllUsersStore?.users?.find(x=>x?.id === userId) || null

  

  return (
    <form onSubmit={e=>onSubmitForm(e)}>
      <AsideCardLayout
        headerTitle={headerTitle}
        headerContent={headerContent}
        headerIcon={headerIcon}
        headetTextSx={{whiteSpace:'nowrap'}}
        rightContent={
          showEditButton
            ? <EditModeButton
                isEdit={isEdit}
                onClose={onCloseEdit ? onCloseEdit : ()=>''}
                onOpenEdit={onOpenEdit ? onOpenEdit : ()=>''}
                onSubmit={()=>''}
                disabledEdit={disabled}
                disabledSubmit={loading}
                loading={loading}
                btnType='submit'
              />
            : null
        }
      >
        <div>
          <Stack sx={{mt:showEditButton ? 1 : 0}} spacing={1}>
            {editTitle &&
              <>
                {editTitle}
                <Divider sx={{borderStyle:'dashed'}}/>
              </>
            }
            <GoodSampleItemWrapper title={isEdit ? 'ID интегации / промокод / канал' : 'ID интегации / промокод'} required={isEdit}>
              {isEdit
                ? <GoodSearchIntegration integration={integration} onSubmit={onChangeIntegration} disabled={disabled}/>
                : <Typography display={'flex'}>{integration?.id} / {integration?.promokod?.code ? integration?.promokod?.code : <EmptyText>промокод отсутствует</EmptyText>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Менеджер'>
              <>
                {isEdit
                  ? AllUsersStore.users
                    ? <CustomSelect
                        value={userId !== null ? userId?.toString() : ''}
                        content={getManagersOnType({allUsers:AllUsersStore.users, managerType:'release'})}
                        onChange={onManagerChange}
                        placeholder='Выберите менеджера'
                        disabled={disabled}
                      />
                    : <Skeleton variant='rounded' sx={{minHeight:'35px', maxHeight:'35px', width:'100%'}}/>
                  : <Typography>{manager ? `${manager?.firstName} ${manager?.lastName}`: <EmptyText/>}</Typography>
                }
              </>
            </GoodSampleItemWrapper>
            <Divider sx={{borderStyle:'dashed'}}/>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'}>
              <Typography variant='h5'>Отправка образца товара</Typography>
              {isEdit &&
                <AddedButton variant='outlined' size='small' onClick={onAddedGood} disabled={disabled}>Добавить товар</AddedButton>
              }
            </Stack>
            <GoodsProductLists 
              list={productSampleList} 
              showDeleteButton={isEdit ? productSampleList?.length>1 : false}
              onDeleteGood={onDeleteGood}
              onChangeGood={onChangeGood}
              disabled={disabled}
              isEdit={isEdit}
            />
            <GoodSampleItemWrapper title='Дата отправки'>
              {isEdit ?
                <DateCustomInput 
                  value={sendAtValue ? dayjs(sendAtValue) : null} 
                  onChange={onChangeDate} 
                  placeholder='дд.мм.год' 
                  disabled={disabled}
                />
                : <Typography>{sendAtValue ? dayjs(sendAtValue)?.format('DD.MM.YYYY') : <EmptyText/>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Стоимость доставки'>
              {isEdit ?
                <GoodsInputNum defaultValue={deliveryCostValue?.toString() || ''} setValue={setDeliveryCostValue} disabled={disabled}/>
                : <Typography>{deliveryCostValue !== null && deliveryCostValue !== undefined ? priceTransormFormat(deliveryCostValue, true, true, true) : <EmptyText/>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Город ОТКУДА отправляем'>
            {isEdit ?
              selectsDataStore?.sourceCities
                ? <CustomSelect
                    content={getCitiesFilterItems(selectsDataStore?.sourceCities)}
                    value={sendFrom || ''}
                    onChange={onChangeCity}
                    fullWidth
                    placeholder='Выберите город'
                    disabled={disabled}
                  />
                : <Skeleton variant='rounded' sx={{minHeight:'35px', maxHeight:'35px', width:'100%'}}/>
              : <Typography>{sendFrom ? selectsDataStore?.sourceCities?.find(x=>x?.code === sendFrom)?.name : <EmptyText/>}</Typography>
            }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Адрес КУДА отправляем'>
              {isEdit ?
                <TextField id='sendTo' size='small' fullWidth placeholder='Введите адрес' disabled={disabled} defaultValue={sendToValue}/>
                : <Typography>{sendToValue || <EmptyText/>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='ФИО'>
              {isEdit ?
                <TextField id='fio' size='small' fullWidth placeholder='Введите ФИО' disabled={disabled} defaultValue={fioValue}/>
                : <Typography>{fioValue || <EmptyText/>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Телефон'>
              {isEdit ?
                <TextField id='phone' size='small' fullWidth placeholder='Введите номер' disabled={disabled} defaultValue={phoneValue}/>
                : <Typography>{phoneValue || <EmptyText/>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Трек номер отправления (транспортная компания)'>
              {isEdit ?
                <TextField id='trackNumberTC' size='small' fullWidth placeholder='Введите трек номер' disabled={disabled} defaultValue={trackNumberTCValue}/>
                : <Typography>{trackNumberTCValue || <EmptyText/>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Трек номер Case Guru'>
              {isEdit ?
                <TextField id='trackNumberCG' size='small' fullWidth placeholder='Введите трек номер CG' disabled={disabled} defaultValue={trackNumberCGValue}/>
                : <Typography>{trackNumberCGValue || <EmptyText/>}</Typography>
              }
            </GoodSampleItemWrapper>
            <GoodSampleItemWrapper title='Отправка с возвратом'>
              <GoodCheckbox value={hasRefundValue} isEdit={isEdit} onChange={onChangeCheckbox} disabled={disabled}/>
            </GoodSampleItemWrapper>
            {showRefundContent &&
              <>
                <Divider sx={{borderStyle:'dashed'}}/>
                <Typography variant='h5'>Возврат образца товара</Typography>
                <GoodSampleItemWrapper title='Трек номер возврата'>
                  {isEdit ?
                    <TextField id='trackNumberRefund' size='small' fullWidth placeholder='Введите трек номер' disabled={disabled} defaultValue={trackNumberRefundValue}/>
                    : <Typography>{trackNumberRefundValue || <EmptyText/>}</Typography>
                  }
                </GoodSampleItemWrapper>
                <GoodSampleItemWrapper title='Стоимость возврата'>
                  {isEdit ?
                    <GoodsInputNum defaultValue={refundCostValue?.toString() || ''} setValue={setRefundCostValue} disabled={disabled}/>
                    : <Typography>{refundCostValue !== null && refundCostValue !== undefined ? priceTransormFormat(refundCostValue, true, true, true) : <EmptyText/>}</Typography>
                  }
                </GoodSampleItemWrapper>
                <GoodSampleItemWrapper title='Образцы возвращены'>
                  <GoodCheckbox value={Boolean(isSampleRefundValue)} isEdit={isEdit} onChange={onChangeSampleRefund ? onChangeSampleRefund : ()=>''} disabled={disabled}/>
                </GoodSampleItemWrapper>
              </>
            }
            {showAddedBtn &&
              <Box>
                <CustomButton 
                  variant='contained'
                  sx={{float:'right'}} 
                  type='submit'
                  startIcon={loading && <CircularProgress sx={{maxHeight:20, minHeight:20, maxWidth:20, minWidth:20}}/>}
                  disabled={loading || disabled}
                >
                  Добавить
                </CustomButton>
              </Box>
            }
          </Stack>
        </div>
      </AsideCardLayout>
    </form>

  )
})