import { FC, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Typography } from "@mui/material"
import { LS_TAKE_REQUEST_AGREEMENTS } from "../../../../../../shared/constants/localstorage"
import { IAgreementsFiltered } from "../../../../../../services/agreements/IAgreementsRequest"
import AgreementsService from "../../../../../../services/agreements/agreements.service"
import dashboardStore from "../../../../../../store/dashboard.store"
import { observer } from "mobx-react-lite"
import { AxiosError } from "axios"
import { getFilterAgreementsQuery } from "./shared/dataList"
import { FinanceSelect } from "../../../../../../components/FinancecTable/ui/FinanceFilter/FinanceSelect"
import { FilterDateMultiple } from "../../../../../../components/Filters/UI/FilterDateMultiple/FilterDateMultiple"
import { getAgreementsStatusInSelect } from "../../shared/dataList"
import { FilterSkeleton } from "../../../../../../components/Filters/UI/FilterSkeleton/FilterSkeleton"
import statusesStore from "../../../../../../store/statuses.store"
import { FilterCheckbox } from "../../../../../../components/Filters/UI/FilterCheckbox/FilterCheckbox"
import { IAgreementFilter } from "./IAgreementFilter"
import { FilterSearchAgreements } from "./ui/FilterSearchAgreements/FilterSearchAgreements"
import { FilterSelectChip } from "../../../../../../components/Filters/UI/FilterSelectChip/FilterSelectChip"
import channelStore from "../../../../../../store/channel.store"
import { getSocialTypeFiltes } from "../../../../../../components/IntegrationTable/shared/dataList"
import { CustomIconButton } from "../../../../../../components/UI/Buttons/CustomIconButton/CustomIconButton"
import { CustomButton } from "../../../../../../components/UI/Buttons/CustomButton/CustomButton"
import { CancelButton } from "../../../../../../components/UI/Buttons/CancelButton/CancelButton"

export const AgreementFilter:FC<IAgreementFilter> = observer((props) => {
  const {onChangeLoading} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [isFiltersOpen, setFilterOpen] = useState(false)

  const getAgreements = (isClear?:boolean) => {
    onChangeLoading(true)

    const option:IAgreementsFiltered = {
      filters:isClear ? {} : getFilterAgreementsQuery(searchParams),
      page:1,
      take:+`${localStorage.getItem(LS_TAKE_REQUEST_AGREEMENTS) ? localStorage.getItem(LS_TAKE_REQUEST_AGREEMENTS) : 100}`
    }
    AgreementsService.getAgreementsFiltered(option)
      .then(res=>{
        // console.log(res, 'res get all agre')

        dashboardStore.addAgreementsAll(res.data.items)
        dashboardStore.setTotalCount(res.data.count)
        dashboardStore.setAgreementsPage(1)

        onChangeLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          // console.log(err, 'err get all agre')
          onChangeLoading(false)
        }
      })
  }

  const clearFilter = () => {
    setSearchParams({})
    getAgreements(true)
  }

  return (
    <>
      <Accordion
        elevation={0}
        expanded={isFiltersOpen}
        disableGutters
        TransitionProps={{timeout:0}}
        sx={{mb: 0,border: "none",borderRadius: "16px","&.MuiAccordion-root": { borderRadius: "16px" }, boxShadow:'0px 0px 8px 0px rgba(34, 60, 80, 0.2)'}}
      >
        <Stack direction={{xs:'column', sm:'row'}} alignItems={{xs:'start', sm:'center'}} justifyContent={'space-between'} sx={{pl:{xs:1, sm:2}, pr:{xs:1, sm:2}}}>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <AccordionSummary
              expandIcon={<CustomIconButton><ExpandMore fontSize="medium" /></CustomIconButton>}
              onClick={() => setFilterOpen((prev) => !prev)}
              sx={{pr:1, pl:0, width:'170px', "&:hover":{color:'#7C64D9'}}}
            >
              <Typography>
                {isFiltersOpen ? "Свернуть фильтр" : "Развернуть фильтр"}
              </Typography>
            </AccordionSummary>
          </Stack>
        </Stack>
        <Divider />
        <AccordionDetails sx={{ p: 1, pb: 2 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:{xl:"repeat(5, 1fr)", lg:'repeat(4,1fr)', md:'repeat(3,1fr)', sm:'repeat(2,1fr)', xs:'repeat(1,1fr)'},
              rowGap:1, 
              columnGap:2,
              pl:1,
              pr:1,
              overflow:'auto',
              maxHeight:{xs:'300px', md:'450px'},
              pb:1
            }}
          >
            <FilterSearchAgreements label="Интеграция" searchParamsName="integrationId"/>
            {statusesStore.agreement
              ? <FinanceSelect
                  label='Статус согласования'
                  searchParamName='status'
                  content={getAgreementsStatusInSelect(statusesStore.agreement)}
                  placeholder='Выберите тип'
                />
              : <FilterSkeleton/>
            }
            {channelStore.channels ? 
                <FilterSelectChip
                  label="Тип канала"
                  content={getSocialTypeFiltes(channelStore.channels)}
                  searchParamName="channelType"
                  placeholder="Введите название"
                  limitTags={3}
                />
               : <FilterSkeleton />
              }
            <FilterDateMultiple
              label="Поставлена на согласование"
              searchParamName="createdAt"
            />
            <FilterDateMultiple
              label="Дата согласования"
              searchParamName="allDecisionDate"
            />
            <Box sx={{display:'flex', alignItems:'end'}}>
              <FilterCheckbox
                label="Мои согласования"
                searchParamName="all_agreements"
              />
            </Box>
          </Box>
          <Divider sx={{margin:'0 -8px'}}/>
          <Box
            sx={{
              mt:2,
              display:"grid",
              gridTemplateColumns:{xs:'1fr', sm:"min-content 1fr min-content"},
              columnGap:1,
              rowGap:{xs:1, sm:0},
              alignItems:"start",
              overflow:'auto',
              maxHeight:82,
              pr:1
            }}
          >
            <Stack direction={{xs:'column', md:"row"}} spacing={1}>
              <CustomButton
                variant="contained"
                onClick={() => getAgreements()}
              >
                Применить
              </CustomButton>
              <CancelButton
                variant="outlined"
                disabled={searchParams.toString() === ""}
                onClick={clearFilter}
              >
                Сбросить
              </CancelButton>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
})