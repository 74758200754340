import {FC, useState, useEffect} from 'react'
import { IMotivationCalcAll, IMotivationPercentForSalesResponse, IMotivationSearchPricesResponse } from '../../../../../../services/motivation/IMotivationResponse'
import { IUserSearchTables } from './IUserSearchTables'
import MotivationService from '../../../../../../services/motivation/motivation.service'
import { AxiosError } from 'axios'
import { CircularProgress, Skeleton, Typography } from '@mui/material'
import { IMotivationSearchPricesRequest, IMotivationSearchRequest } from '../../../../../../services/motivation/IMotivationRequest'
import { Box, Stack } from '@mui/system'
import { SearchMontDatePicker } from '../../../../../../pages/DashboardPage/pages/MotivationPage/ui/SearchMontDatePicker/SearchMontDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { MotivationAllTables } from '../../../../../../pages/DashboardPage/pages/MotivationPage/ui/MotivationAllTables/MotivationAllTables'
import { AsideCardLayout } from '../../../../../AsideCardLayout/AsideCardLayout'
import { CustomButton } from '../../../../../UI/Buttons/CustomButton/CustomButton'
import {ALL_RULES} from "../../../../../../shared/rules/interface/EnumRights";

export const UserSearchTables:FC<IUserSearchTables> = (props) => {
  const {userId,rights} = props

  const [date, setDate] = useState<Dayjs | null>(dayjs())

  const [allSearchTable, setAllSearchTable] = useState<IMotivationSearchPricesResponse | null>(null)
  const [isLoading, setLoading] = useState(false)

  const [searchMotivation, setSearchMotivation] = useState<IMotivationCalcAll | null>(null)
  const [isLoadingSearchMotivation, setLoadingSearchMotivation] = useState(false)

  const [percentForSales, setPercentForSales] = useState<IMotivationPercentForSalesResponse[] | null>(null)
  const [isLoadingPercentForSales, setLoadingPercentForSales] = useState(false)

  const getAllSearch = () => {
    setLoading(true)

    MotivationService.searchPrices()
      .then(res=>{
        setAllSearchTable(res.data)
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
        }
      })
  }

  const getSearchMotivation = (options:IMotivationSearchRequest) => {
    setLoadingSearchMotivation(true)

    const option:IMotivationSearchRequest = {
      month:options.month,
      userId:options.userId,
      year:options.year
    }
    MotivationService.motivationCalcAll(option)
      .then(res=>{
        setSearchMotivation(res.data)
        setLoadingSearchMotivation(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingSearchMotivation(false)
        }
      })
  }

  const getPercentForSales = (params:IMotivationSearchPricesRequest) => {
    setLoadingPercentForSales(true)
    
    MotivationService.percentForSales(params)
      .then(res=>{
        setPercentForSales(res.data)
        setLoadingPercentForSales(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoadingPercentForSales(false)
        }
      })
  }

  const changeDateSearchTables = () => {
    if(date){
      getSearchMotivation({month:date.month()+1, userId, year:date.year()})
      getPercentForSales({month:date.month()+1, userId, year:date.year()})
    }
  }

  const sortHandler = (type: string, sortType: 'asc' | 'desc') => {
    if (type === 'integration_date' && searchMotivation && searchMotivation.forRelease) {
      const sortedReleases = [...searchMotivation.forRelease.motivationReleases].sort((a, b) => {

        const dateA = new Date(a.integration?.integration_date ?? '');
        const dateB = new Date(b.integration?.integration_date ?? '');

        const timeA = dateA.getTime();
        const timeB = dateB.getTime();

        if (sortType === 'asc') {
          return timeA - timeB;
        } else {
          return timeB - timeA;
        }
      });

      const updatedReleaseMotivationByUser = {
        ...searchMotivation,
        forRelease: {
          ...searchMotivation.forRelease,
          motivationReleases: sortedReleases
        }
      };
      setSearchMotivation(updatedReleaseMotivationByUser);
    }
  }


  useEffect(()=>{
    getAllSearch()
    changeDateSearchTables()
  }, [])

  return (
    <Box
      sx={{
        overflow:'auto',
      }}
    >
      {isLoading &&
        <Skeleton variant='rounded' sx={{height:200}}/>
      }
      {!isLoading && allSearchTable &&
        <AsideCardLayout sx={{mt:2, mb:2}}>
          <Stack
            direction={'row'}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
            >
              <SearchMontDatePicker initValue={date} onChange={setDate}/>
              <CustomButton
                variant='contained'
                onClick={changeDateSearchTables} 
                disabled={isNaN(date?.month()!) && isNaN(date?.year()!)}
              >
                применить
              </CustomButton>
            </Stack>
          </Stack>
        </AsideCardLayout>
      }
      {isLoadingSearchMotivation &&
        <Typography variant='h5' textAlign={'center'} alignItems={'center'} sx={{mt:2}}>
          Идёт загрузка таблицы... <CircularProgress sx={{minHeight:25, maxHeight:25, minWidth:25, maxWidth:25, verticalAlign: 'bottom'}}/>
        </Typography>
      }
      {!isLoadingSearchMotivation && searchMotivation &&
        <Box 
          sx={{ 
            display:'flex', 
            flexDirection:'column', 
            height:'auto'
          }}
        >
          <AsideCardLayout>
            <MotivationAllTables
						  hideUrlSortCode
              sortHandler={sortHandler}
              readOnlyPercentChannels={!(rights?.find(x =>x.code?.includes(ALL_RULES.search_head)))}
              userId={userId}
              data={searchMotivation}
              dataSearchChannel={allSearchTable || undefined}
              isShowChannelMotivation
              isShowTableSearchChannelPrice
              readOnlyMotivationUser
              talbeHeaderColor='#f2f3f5'
              readOnlyChannelPrice={true}
              dataSearchPercentChannels={percentForSales ? percentForSales : undefined}
              readOnlyRelease
              motivationType='search'
              managerId={userId}
              showDeleteIconRoiTable={false}
              showAddedSelectRoiTable={false}
              month={date ? date?.month()+1 : new Date().getMonth()+1}
              year={date?.year() || new Date().getFullYear()}
              sizeAudience={null}
            />
          </AsideCardLayout>
        </Box>
      }
    </Box>
  )
}
