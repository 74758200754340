import {FC, useState} from 'react'
import { IGuaranteeReissueControl } from './IGuaranteeReissueControl'
import {Stack, Typography} from '@mui/material'
import { IIntegrationGaranteeRequest } from '../../../../../../services/integration/IntegrationsRequest'
import IntegrationsService from '../../../../../../services/integration/integrations.service'
import integrationPageStore from '../../../../../../store/integrationPage.store'
import { AxiosError } from 'axios'
import integrationTableStore from '../../../../../../store/integrationTable.store'
import {CustomButton} from "../../../../../../components/UI/Buttons/CustomButton/CustomButton";
import {AlertModal} from "../../../../../../components/AlertModal/AlertModal";

export const GuaranteeReissueControl:FC<IGuaranteeReissueControl> = (props) => {
  const {
    isGuaranteed,
    readOnly,
    integrationId,
    id,
    setUpdateId,
    update_id,
    repeatGuaranteeDate
  } = props

  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const change = () => {
    setLoading(true)
    setError(null)

    const options: IIntegrationGaranteeRequest = {
      integrationId:integrationId,
      id:id ? id : undefined,
      repeat:true,
      update_id
    }
    IntegrationsService.setGarant(options)
      .then(res=>{
        integrationPageStore.setGuarantee(res.data?.guarantee || null)
        integrationTableStore.changeGurange(integrationId, res.data?.guarantee || null, res.data.update_id)
        setUpdateId(res.data?.update_id)
        setLoading(false)
        setIsOpen(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <Typography>Перевыпуск по гаранту:</Typography>
        {!isGuaranteed && <CustomButton
          sx={{padding:'2px 8px'}}
          color={error ? 'error' : 'primary'}
          onClick={()=> setIsOpen(true)}
          disabled={readOnly || isLoading}
        >
          ПОСТАВИТЬ
        </CustomButton>
        }
        {isGuaranteed && repeatGuaranteeDate && <Typography>{`поставлена ${repeatGuaranteeDate.toLocaleDateString()}`}</Typography>}
        {isGuaranteed && !repeatGuaranteeDate && '–'}
      </Stack>
      {isOpen &&
        <AlertModal
         disabled={isLoading}
         subContent={'Вы действительно хотите поставить интеграцию на перевыпуск по гаранту?'}
         title={'Постановка на перевыпуск по гаранту'}
         onSubmit={change}
         buttonCancelName={'Отмена'}
         buttonSubmitName={'Поставить'}
         open={isOpen}
         onClose={()=>setIsOpen(false)}/>
      }
    </>
  )
}
