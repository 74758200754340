import { IFilterSelectItem } from "../../../../../Filters/UI/FilterSelectChip/IFilterSelectChip"
import { IGoodItem } from "../../GoodsField/ui/GoodItem/IGoodItem"

export const getTzGoodsList = (goods:IGoodItem[]):IFilterSelectItem[] => {

  const listItems:IFilterSelectItem[] = goods.map(good=>{
    const newItem:IFilterSelectItem = {
      name:good.name,
      value:good.article
    }
    return newItem
  })
  
  return listItems
}