import {FC, useState} from 'react'
import {IDrawer} from '../../entities/Drawer/models/IDrawer'
import {Box, CircularProgress, Divider, Drawer, List, Stack, Tooltip, Typography} from '@mui/material'
import {Cancel, Logout} from '@mui/icons-material'
import {AuthService} from '../../services'
import userStore from '../../store/user.store'
import {LS_SAVE_WIDTH_USER, LS_TAKE_REQUEST_AGREEMENTS, LS_TAKE_REQUEST_CHANNEL, LS_TAKE_REQUEST_FINANCES, LS_TAKE_REQUEST_GOODS, LS_TAKE_REQUEST_INTEGRATION, LS_TAKE_REQUEST_TZ} from '../../shared/constants/localstorage'
import {observer} from 'mobx-react-lite'
import {AsideTabs} from '../../pages/TableAsidePage/ui/AsideTabs/AsideTabs'
import {ITabItem} from '../../pages/TableAsidePage/ui/AsideTabs/IAsideTabs'
import {UserMainPage} from './pages/UserMainPage/UserMainPage'
import {UserMotivationPage} from './pages/UserMotivationPage/UserMotivationPage'
import {ALL_RULES} from '../../shared/rules/interface/EnumRights'
import {UserRepeatPage} from './pages/UserRepeatPage/UserRepeatPage'
import {UserPlanPage} from "./pages/UserPlanPage/UserPlanPage";
import { useResizeHook } from '../../hooks/useResizeHook'
import { ResizeButton } from '../ResizeButton/ResizeButton'
import { CustomIconButton } from '../UI/Buttons/CustomIconButton/CustomIconButton'
import { CustomButton } from '../UI/Buttons/CustomButton/CustomButton'

export const Aside: FC<IDrawer> = observer((props) => {
  const {anchor, open, onClose} = props

  const [subPage, setSubPage] = useState(0)

  const userTabSubPages: ITabItem[] = [
    {name: 'Общее', value: 0},
    {
      name: 'План',
      value: 1,
      show: userStore.user?.rights?.find(x => x.code?.includes(ALL_RULES.search) || x.code?.includes(ALL_RULES.release)) ? true : false
    },
    {
      name: 'Мотивация',
      value: 2,
      show: userStore.user?.rights?.find(x => x.code === ALL_RULES.lk_my_motivation) ? true : false
    },
    {
      name: 'Повтор Интеграций',
      value: 3,
      show: userStore.user?.rights?.find(x => x.code === ALL_RULES.release) ? true : false
    },
  ]

  const handelLogout = async () => {
    try {
      const res = await AuthService.logout()

      if (res.status === 200) {
        localStorage.removeItem('token')
        localStorage.removeItem(LS_TAKE_REQUEST_CHANNEL)
        localStorage.removeItem(LS_TAKE_REQUEST_INTEGRATION)
        localStorage.removeItem(LS_TAKE_REQUEST_FINANCES)
        localStorage.removeItem(LS_TAKE_REQUEST_TZ)
        localStorage.removeItem(LS_TAKE_REQUEST_AGREEMENTS)
        localStorage.removeItem(LS_TAKE_REQUEST_GOODS)

        window.location.reload()
      }

    } catch (err) {

    }
  }

  const closeUserPage = () => {
    onClose()
    setSubPage(0)
  }

  const [onMouseDown, onMouseMove, onMouseUp, startResize] = useResizeHook('user-resize-page', LS_SAVE_WIDTH_USER)

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={closeUserPage}
      PaperProps={{sx: {backgroundColor: "#F2F3F4"}}}
      onMouseMove={startResize ? onMouseMove : undefined}
    >
      <List
        id={'user-resize-page'}
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          minWidth: {lg: '1200px', sm: 'calc(100vw - 70px)', xs: '100vw'},
          width:{
            lg:localStorage.getItem(LS_SAVE_WIDTH_USER) ? `calc(${localStorage.getItem(LS_SAVE_WIDTH_USER)+'vw'} - 70px)` : '1200px',
            sm: 'calc(100vw - 70px)',
          },
          overflow: 'hidden',
          position:'relative'
        }}
      >
        <ResizeButton onMouseDown={onMouseDown} onMouseUp={onMouseUp} sx={{display:{xs:'none', lg:'block'}}}/>
        {userStore.user === null &&
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{height: '100%'}}
          >
            <CircularProgress sx={{minHeight: 100, minWidth: 100}}/>
          </Stack>
        }
        {userStore.user &&
				 <>
					<Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              p: 2,
              pt: 0,
              pb: 1,
              pl: 1,
            }}
					>
            <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
              <Tooltip title='Закрыть' placement='left'>
                <Box>
                  <CustomIconButton size='medium' onClick={closeUserPage}>
                    <Cancel/>
                  </CustomIconButton>
                </Box>
              </Tooltip>
              <Typography variant='h5' sx={{fontSize:{xs: '16px', sm:'1.28rem'}}}>{userStore.user.firstName} {userStore.user.lastName}</Typography>
            </Stack>
            <CustomButton variant='outlined' startIcon={<Logout/>} onClick={handelLogout}>
              Выход
            </CustomButton>
					</Stack>
					<Box
            sx={{
              pl: {xs: 1, sm: 2},
              pr: {xs: 1, sm: 2},
              height: subPage === 1 ? '90%' : 'inherit'
            }}
					>
            <Divider/>
            <AsideTabs initValue={subPage} setValue={setSubPage as any} tabs={userTabSubPages}/>
            {subPage === 0 && <UserMainPage {...userStore.user}/>}
            {subPage === 1 && <UserPlanPage isLk={true}/>}
            {subPage === 2 && <UserMotivationPage {...userStore.user}/>}
            {subPage === 3 && <UserRepeatPage {...userStore.user}/>}
					</Box>
				 </>
        }
      </List>
    </Drawer>
  )
})