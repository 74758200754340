import {FC} from 'react';
import {IGoodsModalList} from './IGoodsModalList';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from '@mui/material';
import {Cancel} from '@mui/icons-material';
import {GoodItem} from '../GoodItem/GoodItem';
import { CustomIconButton } from '../../../../../../UI/Buttons/CustomIconButton/CustomIconButton';
import { CancelButton } from '../../../../../../UI/Buttons/CancelButton/CancelButton';
import { CustomButton } from '../../../../../../UI/Buttons/CustomButton/CustomButton';

export const GoodsModalList: FC<IGoodsModalList> = (props) => {
  const {
    id,
    goods,
    open,
    onClose,
    loading = false,
    onSubmit,
    changeGoods,
    forFirstIntegrationWithChannel
  } = props

  const categories: string[] = goods.reduce((unique: string[], item) => {
    if (!unique.includes(item.category.name)) {
      unique.push(item.category.name)
    }
    return unique
  }, [])

  categories.sort((a, b) => a.localeCompare(b))

  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', pr: 1}}>
        {id
          ? <Typography>Изменение списка товаров у интеграции <b>№{id}</b></Typography>
          : <Typography>Изменение списка товаров</Typography>
        }
        <CustomIconButton disabled={loading} onClick={onClose}>
          <Cancel/>
        </CustomIconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        {categories.map((category, categoryInd) => {
          const categoryGoods = goods
            .filter(x=>forFirstIntegrationWithChannel === true ? x.forFirstIntegrationWithChannel : x.name === x.name)
            .filter(item => item.category.name === category)
            .sort((a, b) => a.name.localeCompare(b.name))

          if (categoryGoods.length > 0) {
            return (
              <div key={category}>
                <Typography variant="h6" sx={{mb: 0.5, mt:categoryInd === 0 ? 0 : 0.5}}>{category}</Typography>
                {categoryGoods.map(item => (
                  <GoodItem
                    key={item.article}
                    name={item.name}
                    article={item.article}
                    isActive={item.isActive}
                    checkbox
                    onChange={changeGoods}
                    disabled={loading}
                    category={item.category}
                    forFirstIntegrationWithChannel={item.forFirstIntegrationWithChannel}
                  />
                ))}
              </div>
            )
          }
          return null
        })}
      </DialogContent>
      <Divider/>
      <DialogActions>
        <CancelButton variant='outlined' disabled={loading} onClick={onClose}>Отмена</CancelButton>
        <CustomButton
          variant='contained'
          disabled={loading}
          onClick={onSubmit}
          endIcon={loading && <CircularProgress sx={{maxHeight: 20, maxWidth: 20}}/>}
        >
          Сохранить
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};