import {FC, useState, useEffect} from 'react'
import {IUser} from '../../../../entities/user/models/IUser'
import IntegrationsService from '../../../../services/integration/integrations.service'
import {IIntegrationGetCandidatesForRepeatRequest} from '../../../../services/integration/IntegrationsRequest'
import {AxiosError} from 'axios'
import dayjs, {Dayjs} from 'dayjs'
import {Backdrop, Box, CircularProgress, Stack, Typography} from '@mui/material'
import {RepeatTable} from './ui/RepeatTable/RepeatTable'
import {observer} from 'mobx-react-lite'
import repeatIntegrationStore from '../../../../store/repeatIntegration.store'
import {CHANNEL_CARD_OPEN_REPEAT, INTEGRATION_CARD_OPEN_REPEAT} from '../../../../shared/constants/searchParams'
import {useSearchParams} from 'react-router-dom'
import {TableAsidePage} from '../../../../pages/TableAsidePage/TableAsidePage'
import {ChannelCardPage} from '../../../../pages/ChannelCardPage/ChannelCardPage'
import { AsideCardLayout } from '../../../AsideCardLayout/AsideCardLayout'

export const UserRepeatPage: FC<IUser> = observer((props) => {
  const {
    company,
    email,
    firstName,
    id,
    imageUrl,
    isActive,
    lastName,
    rights,
    role,
    roleName
  } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const [date, setDate] = useState<null | Dayjs>(dayjs())

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState<string | null>(null)

  const getCandidatesForRepeat = (params: IIntegrationGetCandidatesForRepeatRequest) => {
    setLoading(true)
    setError(null)

    IntegrationsService.getCandidatesForRepeat(params)
      .then(res => {
        // console.log(res, 'get repeats')

        repeatIntegrationStore.setIntegrations(res.data)
        setLoading(false)
      })
      .catch(err => {
        if (err instanceof AxiosError) {
          console.log(err, 'err repeats')
          // setError(err?.response?.data?.message)
          setLoading(false)
        }
      })
  }

  const navigateAside = (id: string | number) => {
    const params = new URLSearchParams(window.location.search)
    params.set(INTEGRATION_CARD_OPEN_REPEAT, id?.toString())

    searchParams.set(INTEGRATION_CARD_OPEN_REPEAT, id?.toString())

    setSearchParams(params)
  }

  const closeIntegration = () => {
    searchParams.delete(INTEGRATION_CARD_OPEN_REPEAT)
    setSearchParams(searchParams)
  }

  const closeChannel = () => {
    searchParams.delete(CHANNEL_CARD_OPEN_REPEAT)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (date) {
      getCandidatesForRepeat({
        userId: id,
        month: date?.month() + 1,
        year: date?.year()
      })
    }

    return () => {
      repeatIntegrationStore.setIntegrations(null)
    }
  }, [])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, height:'100vh'}}
        open={repeatIntegrationStore.isLoadingSort}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      <AsideCardLayout sx={{mt:2}}>
        <Box
          sx={{
            overflow: 'hidden',
            height: '83vh',
          }}
        >
          {isLoading &&
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
              justifyContent={'center'}
            >
              <Typography variant='h5'>Идёт загрука интеграций...</Typography>
              <CircularProgress sx={{height: 50, width: 50}}/>
            </Stack>
          }
          {!isLoading && repeatIntegrationStore.inegrations !== null &&
          <Stack
            direction={'column'}
            spacing={2}
            sx={{ overflow: 'hidden', height: '100%'}}
          >
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              alignItems={{xs: 'start', sm: 'center'}}
              justifyContent={'space-between'}
              spacing={{xs: 1, sm: 0}}
            >
              <Typography variant='h6'>Таблица интеграций на повтор</Typography>
            </Stack>
            {repeatIntegrationStore.inegrations !== null && !!repeatIntegrationStore.inegrations?.length
              ? <RepeatTable integrations={repeatIntegrationStore.inegrations} navigateAside={navigateAside}/>
              : <Typography variant='h5' textAlign={'center'}>Интеграций за выбранный период не найдено</Typography>
            }
          </Stack>
          }
        </Box>
      </AsideCardLayout>
      {searchParams.get(INTEGRATION_CARD_OPEN_REPEAT) &&
			 <TableAsidePage
          integrationId={searchParams.get(INTEGRATION_CARD_OPEN_REPEAT)!}
          onClose={closeIntegration}
          channelSearchParamsKey={CHANNEL_CARD_OPEN_REPEAT}
			 />
      }
      {searchParams.get(CHANNEL_CARD_OPEN_REPEAT) &&
			 <ChannelCardPage channelId={searchParams.get(CHANNEL_CARD_OPEN_REPEAT)!} onClose={closeChannel}/>
      }
    </>
  )
})