import {useCallback, useMemo} from "react";
import statusesStore from "../../../store/statuses.store";
import {IChannel} from "../../../services/channel/ICnannelResponse";
import {IUpdateStatusesRequest} from "../../../services/integration/IntegrationsRequest";
import ChannelService from "../../../services/channel/channel.service";
import channelProcessedStore from "../../../store/channelProcessed.store";
import {AxiosError} from "axios";
import userStore from "../../../store/user.store";
import {ALL_RULES} from "../../../shared/rules/interface/EnumRights";
import {TableItem} from "../../Table/ui/TableItem";
import {IProcessedChannelsList} from "./IProcessedChannelsList";
import {useChannelNavigation} from "../../../hooks/useChannelNavigation";

export const ProcessedChannelsList = (props: IProcessedChannelsList) => {

  const {cells} = props

  const navigateChannel = useChannelNavigation();

  const getStatusAllowedMoves = useMemo(():string[] => {
    return Object.keys(statusesStore.channel)
  },[])

  const onDeleteItemHandler= useCallback( (item:IChannel)=>{
    const option:IUpdateStatusesRequest = {
      id:item.id,
      field:'isProcessed',
      new_value:null!,
      update_id:item.update_id
    }
    ChannelService.updateSimpleField(option)
      .then(res=>{
        channelProcessedStore.deleteProcessedChannelsItem(res.data);
        channelProcessedStore.deleteProcessedItem(res.data)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
        }
      })
  },[])


  return (
    <>
      {channelProcessedStore.tableItems?.map((item) => {
        const channelProps = {
          ...item,
          theme: item?.theme?.name,
          fieldNotCode:'releaseById',
          onChange: channelProcessedStore.deleteProcessedChannelsItem,
          onChangeManager: channelProcessedStore.addReleaseByManager,
          readOnly: !(userStore.user?.rights?.find(x => x.code?.includes(ALL_RULES.edit_channel_transfer) || x.code?.includes(ALL_RULES.ADMIN))),
        }
        return <TableItem {...channelProps}
                          onChangeStatus={channelProcessedStore.changeUpdateIdItem}
                          onUpdateStatus={ChannelService.updateSimpleField}
                          statusStore={statusesStore.channel}
                          isShowNavigateIcon
                          onNavigate={navigateChannel}
                          key={item.id}
                          cells={cells}
                          allAllowedMoves={getStatusAllowedMoves}
                          showDeleteIcon
                          onDeleteItem={onDeleteItemHandler}
        />;
      })}
    </>
  )
}