import {FC} from 'react'
import { ITableFooter } from '../models/ITableFooter'
import {Pagination, PaginationItem, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import { CustomButton } from '../../UI/Buttons/CustomButton/CustomButton'


 const buttonInteractionStyles = {
  '&:hover': {
    color: '#7C64D9',
    background: '#E2DBFF',
  },
  '&:active': {
    color: '#503D9D',
    background: '#E2DBFF',
  }
};

const buttonActiveInteractionStyles = {
  backgroundColor: '#E2DBFF',
  color: '#503D9D',
  "&:hover":{
    bgcolor:'#E2DBFF'
  }
};

export const TableCustomFooter:FC<ITableFooter> = (props) => {
  const {
    hideBorder,
    toggleValues,
    onChaneToggleButton,
    totalItems,
    onExportExcel,
    toggleItems,
    onChangePage,
    totalCount,
    paginateStep,
    page,
    disabledExportExcelBtn,
    showBoxShadow
  } = props

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if(onChangePage){
      onChangePage(value)
    }
  }

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        border:hideBorder ? 'none' : '1px solid #e0e0e0',
        borderTop:hideBorder ? '1px solid #e0e0e0' : 'none',
        p:1,
        mt:'-1px',
        background:'#fff',
        borderBottomLeftRadius:'4px',
        borderBottomRightRadius:'4px',
        overflow:'auto',
        boxShadow:showBoxShadow ? '0px 4px 10px -2px rgba(34, 60, 80, 0.2)' : undefined,
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          overflow: 'auto',
          minWidth: '650px',
          width: '100%',
        }}
      >
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography sx={{display:{xs:'none', md:'inline-block'}}}>Кол-во строк:</Typography>
          <ToggleButtonGroup value={toggleValues} onChange={onChaneToggleButton} exclusive>
            {toggleItems?.map(item=><ToggleButton key={item} value={item} sx={{p: 1,'&&':{ ...(toggleValues?.includes(item) ? buttonActiveInteractionStyles : buttonInteractionStyles)}}}>{item}</ToggleButton>)}
          </ToggleButtonGroup>
          <Typography>Всего: {totalCount}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          {onExportExcel && <CustomButton variant='outlined' onClick={onExportExcel} disabled={disabledExportExcelBtn}>Выгрузить в Excel</CustomButton>}
          {totalCount && paginateStep ?
            <Pagination
              page={page}
              onChange={handleChange} 
              count={Math.ceil(totalCount / paginateStep)}
              size='small'
              renderItem={(item) => <PaginationItem sx={{'&&': {...(item.selected ? buttonActiveInteractionStyles : buttonInteractionStyles)}}} {...item}/>}
            />
          : null}
        </Stack>
      </Stack>
    </Stack>
  )
}