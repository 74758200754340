import {useEffect,useState} from "react";
import {observer} from "mobx-react-lite";
import ChannelService from "../../services/channel/channel.service";
import {AxiosError} from "axios";
import {AsideCardLayout} from "../AsideCardLayout/AsideCardLayout";
import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import {TableDefault} from "../Table/TableDefault";
import channelProcessedStore from "../../store/channelProcessed.store";
import {ProcessedChannelsList} from "./ui/ProcessedChannelsList";
import {getProcessedChannelsItems} from "./shared/dataList";



export const ProcessedChannels = observer(() => {

  const [tableError, setTableError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentSortType, setCurrentSortType] = useState('')

  const sortHandler = (type:string, sortType: 'asc' | 'desc') => channelProcessedStore.sortTableItem(type, sortType)

  const getProcessedChannelData = async () => {
    setIsLoading(true)
    setTableError(null)
    try {
      const res = await ChannelService.getProcessedChannels()
      channelProcessedStore.setTableItems(res.data)
    } catch (err) {
      if (err instanceof AxiosError) {
        setTableError(err.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getProcessedChannelData()
    return ()=>{
      channelProcessedStore.setTableItems([])
    }
  }, []);

  return (
    <>
      {isLoading &&
			 <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
				Идёт загрузка таблицы... <CircularProgress sx={{ml: 2}}/>
			 </Box>
      }
      {!isLoading && tableError &&
			 <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
				<Typography variant='h5' color='error'>Ошибка с таблицей! {tableError}</Typography>
			 </Box>
      }
      {!channelProcessedStore.tableItems.length && !isLoading &&
			 <Stack alignItems={'center'} sx={{mt:2}}>
				<Typography variant='h4'>Каналы не найдены</Typography>
				<Typography variant='h5'>Обновите страницу или зайдите позже</Typography>
			 </Stack>
      }
      {!isLoading && !tableError && channelProcessedStore.tableItems.length > 0 &&
			 <AsideCardLayout sx={{p: {xs: 1, sm: 2}, overflow: 'auto'}}>
				<Box mb={2} borderBottom={'1px solid #e0e0e0'} borderRadius={'4px'} overflow={'hidden'}>
				 <Typography variant={'h5'} mb={2}>Каналы на удаление</Typography>
				 <TableDefault
          onChangeCurrentSortType={setCurrentSortType}
          currentSortType={currentSortType}
          sortHandler={sortHandler}
          isShowSort
          maxHeight={'75vh'}
          showDeleteIcon
          showBorderRadius={false}
          tableRow={getProcessedChannelsItems()}
          showBurgerCell>
           <ProcessedChannelsList cells={getProcessedChannelsItems()}  />
				 </TableDefault>
				</Box>
			 </AsideCardLayout>
      }
    </>
  )
})