import  { FC, useEffect, useRef, useState } from 'react'
import { Box, Divider, TextField } from '@mui/material'
import { PostedCommentItem } from '../../../../components/PostedCommentItem/PostedCommentItem'
import { getNewDay } from '../../../../components/PostedCommentItem/shared/function'
import { IAsideCardComments } from './IAsideCardComments'
import CommonService from '../../../../services/common/common.service'
import { IDeleteCommentsRequest, IEditCommentsRequest } from '../../../../services/common/ICommonRequest'
import { AxiosError } from 'axios'
import { AsideCardLayout } from '../../../../components/AsideCardLayout/AsideCardLayout'
import { ArrowBackIosNew, ArrowForwardIos, Chat } from '@mui/icons-material'
import { getDatePlusCustomTime } from '../../../../shared/functions/functions'
import {EmptyText} from "../../../../components/EmptyText/EmptyText";
import { CustomButton } from '../../../../components/UI/Buttons/CustomButton/CustomButton'
import { CustomIconButton } from '../../../../components/UI/Buttons/CustomIconButton/CustomIconButton'
import { CancelButton } from '../../../../components/UI/Buttons/CancelButton/CancelButton'

export const AsideCardComments:FC<IAsideCardComments> = (props) => {
  const {
    comments,
    addComment, 
    readOnly = false, 
    maxHeight, 
    sx,
    entity,
    onChangeComments,
    onAddLastComment,
    entityId,
    onEditComments,
    onDeleteComments,
    onCloseChat,
    onOpenChat,
    sxBtnResize,
    showResizeBtn,
    onUpdateCommentsArray
  } = props

  const [open, setOpen] = useState(true)

  const [editId, setEditId] = useState<number | null>(null)

  const [value, setValue] = useState('')
  const contentRef = useRef<HTMLElement | null>(null)

  const deleteComment = (id:number) => {
    const option:IDeleteCommentsRequest = {
      entity,
      id
    }
    CommonService.deleteComments(option)
      .then(res=>{

        onChangeComments(prev=>{
          const delItems = prev?.filter(item=>item.id !== res.data.id)

          if(onAddLastComment){
            onAddLastComment(entityId, delItems[delItems.length-1])
          }

          if(onUpdateCommentsArray){
            onUpdateCommentsArray(entityId, delItems)
          }

          return delItems
        })

        if(onDeleteComments){
          onDeleteComments(id)
        }

      })
      .catch(err=>{
        if(err instanceof AxiosError){

        }
      })
  }

  const onEditComment = () => {
    if(editId){
      const option:IEditCommentsRequest = {
        entity,
        id:editId,
        text:value
      }
      CommonService.editComments(option)
        .then(res=>{

          onChangeComments(prev=>{
            const newItems = prev?.map(item=>item.id === res.data.id ? {...res.data} : item)
            
            if(onAddLastComment){
              onAddLastComment(entityId, newItems[newItems.length-1])
            }

            if(onUpdateCommentsArray){
              onUpdateCommentsArray(entityId, newItems)
            }

            return newItems
          })

          if(onEditComments){
            onEditComments(editId, res.data)
          }

          setEditId(null)
        })
        .catch(err=>{
          if(err instanceof AxiosError){

          }
        })
    }
  }

  const changeEdit = (id:number, text:string) => {
    setEditId(id)
    setValue(text)
  }

  const closeEdit = () => {
    setEditId(null)
    setValue('')
  }

  const scrollDown = () => {
    contentRef?.current?.scrollTo({top: contentRef?.current.scrollHeight, behavior:'smooth'})
  }

  const submitComments = () => {

    if(editId === null){
      addComment(value)
    } else {
      onEditComment()
    }

    setValue('')
  }

  const onKeyDownSend = (e:React.KeyboardEvent) => {
    if(e.code === "Enter" || e.code === "NumpadEnter"){
      submitComments()
    }
  }

  const openChat = () => {
    if(open){
      setOpen(prev=>false)

      if(onCloseChat){
        onCloseChat()
      }

    } else {
      setOpen(prev=>true)
      
      if(onOpenChat){
        onOpenChat()
      }
      
    }
  }

  useEffect(()=>{
    scrollDown()
  },[comments])

  return (
    <Box
      sx={{height:'fit-content', position:'relative'}}
    >
      {showResizeBtn &&
        <Box 
          sx={{
            position:'absolute', 
            top:'10px', 
            left:'-10px',
            height:20, 
            width:20,
            ...sxBtnResize
          }}
        >
          <CustomIconButton 
            size='small' 
            onClick={openChat} 
            sx={{
              p:0, 
              display:'flex', 
              alignItems:'center', 
              justifyContent:'center',
              height:'100%',
              width:'100%'
            }}
            showBackground
          >
            {open
              ? <ArrowForwardIos fontSize='small' sx={{color:'#fff', height:14, width:14, p:0}}/>
              : <ArrowBackIosNew fontSize='small' sx={{color:'#fff', height:14, width:14, p:0}}/>
            }
          </CustomIconButton>
        </Box>
      }
      {!open &&
        <AsideCardLayout sx={{height:'175px'}}>
          {comments.length > 0 ? <Chat color='error' sx={{ml:1}}/> : null}
        </AsideCardLayout>
      }
      {open &&
        <AsideCardLayout
          headerTitle={'комментарии'}
          sx={{height:'fit-content'}}
          headetTextSx={{ml:1}}
        >
          <Box ref={contentRef} sx={{mt:2, maxHeight:maxHeight ? maxHeight : '300px', overflow:'auto', ...sx}}>
            <Box sx={{mb:1}}>
              {comments.length > 0
                ? comments.map((item, i, arr)=>
                    <Box key={item.id} sx={{mt:i === 0 ? 0 : 1}}>
                      <PostedCommentItem
                        newDay={
                          getNewDay(arr[i-1] ? getDatePlusCustomTime({value:arr[i-1].postedAt}) : undefined, getDatePlusCustomTime({value:item.postedAt}))
                        }
                        {...item}
                        onDelete={deleteComment}
                        onEdit={changeEdit}
                      />
                    </Box>)
                : <EmptyText sx={{mb:2}}>Здесь будут комментарии</EmptyText>
              }
            </Box>
              {!readOnly &&
                <>
                  <Divider sx={{mb:2}}/>
                  <Box sx={{display:'flex', mt:'auto'}}>
                    <TextField
                      value={value}
                      onChange={(e)=>setValue(e.target.value)}
                      fullWidth
                      placeholder={editId !== null ? 'Измените текст' : 'Введите текст...'}
                      sx={{"& fieldset": { borderRadius:'0px', borderBottomLeftRadius:'inherit' }}}
                      onKeyDown={onKeyDownSend}
                      size='small'
                    />
                    {editId !== null &&
                      <CancelButton
                        variant='outlined'
                        onClick={closeEdit}
                        sx={{borderRadius:'0px'}}
                        size='small'
                      >
                        Отмена
                      </CancelButton>
                    }
                    <CustomButton
                      disabled={value.trim()===''}
                      variant='contained'
                      onClick={submitComments}
                      sx={{borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px'}}
                      size='small'
                    >
                      {editId !== null ? 'Изменить' : 'Отправить'}
                    </CustomButton>
                  </Box>
                </>
              }
          </Box>
        </AsideCardLayout>
      }
    </Box>
  )
}
