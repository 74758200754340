import { makeAutoObservable } from "mobx";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";
import {IChannel, IManagerChannels,} from "../services/channel/ICnannelResponse";

class TransferredChannelsStore {
  tableItems: IChannel[] = [];
  tableAccordionItems: IManagerChannels[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  setTableItems(items: IChannel[]) {
    this.tableItems = items
  }

  setTableAccordionItems(items: IManagerChannels[]) {
    this.tableAccordionItems = items;
  }

  changeField(option: IUpdateStatusesRequest) {
    const {field, id, new_value, update_id} = option
    this.tableItems = this.tableItems.map(item => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: new_value
        }
        return newItem
      } else return item
    })
  }

  changeAccordionField = (option: IUpdateStatusesRequest) => {
    const {field, id, new_value, update_id} = option
    this.tableAccordionItems = this.tableAccordionItems.map(managerItem => ({
      ...managerItem,
      channels: managerItem.channels.map(channel => {
        if (channel.id === id) {
          const newItem = {
            ...channel,
            update_id,
            [field]: new_value
          };
          return newItem;
        }
        return channel;
      })
    }));
  };

  deleteTransferredChannelsItem = (id: number) => {
    if (this.tableItems) {
      this.tableItems = this.tableItems.filter(item => item.id !== id)
    }
  }
  deleteTransferredAccordionChannelsItem = (value:string,id:number) => {
    const tableAccordionItems = this.tableAccordionItems;
    if (tableAccordionItems) {
      if (value !== 'to_work' && value !== 'peredan' && value !== 'bargain') {
        this.tableAccordionItems = tableAccordionItems.map(managerItem => {
          return {
            ...managerItem,
            channels: managerItem.channels.filter(channel => channel.id !== id)
          };
        });
      }
    }
  }


  deleteTransferredChannelsItemByStatus = (status:string,id:number) => {
    if (this.tableItems) {
      if (status !== 'peredan') {
        this.tableItems= this.tableItems.filter(el => el.id !== id);
      }
    }
  };

  changeAccordionChannelUpdateIdItem = (id: number, update_id: number, type: string) => {
    this.tableAccordionItems = this.tableAccordionItems.map(managerItem => ({
      ...managerItem,
      channels: managerItem.channels.map(channel => {
        if (channel.id === id) {
          const newItem = {
            ...channel,
            update_id,
            status: { code: type }
          };
          return newItem;
        }
        return channel;
      })
    }));
  };

  addReleaseByManager = (id: number, field: string, update_id: number, value: number) => {
    this.tableAccordionItems = this.tableAccordionItems.map(managerItem => ({
      ...managerItem,
      channels: managerItem.channels.map(channel => {
        if (channel.id === id) {
          const newItem = {
            ...channel,
            update_id,
            [field]: { id: value }
          };
          return newItem;
        }
        return channel;
      })
    }));
  };

  changeUpdateIdItem = (id:number, update_id:number, type:string) => {
    this.tableItems = this.tableItems.map(item=>{
      if(item.id === id){
        const newItem = {
          ...item,
          update_id,
          status:{code:type}
        }
        return newItem
      } return item
    })
  }

  addTransferredChannelsItem = (item: IChannel) => {
    if (this.tableAccordionItems) {
      const manager = this.tableAccordionItems.find(manager => manager.managerId === item.releaseById.toString());
      if (manager) {
        manager.channels.unshift(item);
      }
    }
  }
}

export default new TransferredChannelsStore();
