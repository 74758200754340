import { FC, useState, useEffect } from 'react'
import { IOrdSelect } from './IOrdSelect'
import { AxiosError } from 'axios'
import { Skeleton } from '@mui/material'
import { IFilterSelectItem } from '../../../../../../../../components/Filters/UI/FilterSelectChip/IFilterSelectChip'
import ReferencesService from '../../../../../../../../services/references/references.service'
import { CustomSelect } from '../../../../../../../../components/UI/CustomSelect/CustomSelect'

export const OrdSelect:FC<IOrdSelect> = (props) => {
  const {onChange, value, id} = props

  const [isLoading, setLoading] = useState(false)
  const [list, setList] = useState<null | IFilterSelectItem[]>(null)

  const getFormatList = () => {
    setLoading(true)

    ReferencesService.advertisingFormat()
      .then(res=>{
        setList(prev=>res.data.map(x=>{
          const newItem:IFilterSelectItem = {
            name:x.name,
            value:x.code
          }
          return newItem
        }))
        setLoading(false)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          setLoading(false)
        }
      })
  }

  useEffect(()=>{
    getFormatList()
  }, [])

  return (
    <>
      {!isLoading && list &&
        <CustomSelect
          content={list}
          value={value}
          onChange={(e)=>onChange(id, e)}
          placeholder='Выберите значение'
          fullWidth
        />
      }
      {isLoading &&
        <Skeleton variant='rounded' sx={{minHeight:37, maxHeight:37, width:'100%'}}/>
      }
    </>
  )
}
